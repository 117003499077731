import React, { useState, useEffect } from "react";
import { Alert, Form, Button, Col, Row } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';

import EmployeeGroupCourses from "./EmployeeGroupCourses.jsx";
import { employeeGroupApi } from "../../../api/employeeGroup.js";
import { generatePassword } from "../../../_helpers/generatePassword.js";

export default function EmployeeGroupEdit(props) {
    
    const { client, clientId, groupId, setIsEditingGroup, setIsUpdated } = props;
    const { Formik } = formik;    

    const [pageState, setPageState] = useState({pageError: null}); 
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [showEmailInputs, setShowEmailInputs] = useState(false);

    const groupDefaults = {
        clientId: clientId,
        userName: "",
        passKey: generatePassword(),
        name: "",
        askPostLoginQuestions: true,
        mustCompleteCourse: true,
        generateCertificate: false,
        sendManagerEmail: false,
        emailMessage: "",
        emailOnlySentWhenPassed: false,
        selectedManagerEmail: "",
        passMessage: "",
        failMessage: "",
        showTakeAnotherCourse: false
    }
    
	const getGroupData = async () => {
		const group = await employeeGroupApi.getEmployeeGroup(groupId);
		setSelectedGroup(group);
        setShowEmailInputs(group.sendManagerEmail);
	};

	useEffect(() => {
		if (groupId) getGroupData();
	}, [groupId]);

    const schema = yup.object().shape({
        clientId: yup.string().required(),
        name: yup.string().required(),
        userName: yup.string().required(),
        passKey: yup.string().required(),
        askPostLoginQuestions: yup.boolean(),
        mustCompleteCourse: yup.boolean(),
        showTakeAnotherCourse: yup.boolean(),
        generateCertificate: yup.boolean(),
        sendManagerEmail: yup.boolean(),
        emailOnlySentWhenPassed: yup.boolean(),
        emailMessage: yup.string().nullable(),
        selectedManagerEmail: yup.string().nullable(),
        passMessage: yup.string().nullable(),
        failMessage: yup.string().nullable()
    });

    return (
        <>
            <Row className="ms-3 mb-3">
                {selectedGroup ? <h5 className="text-secondary mb-3">Edit Employee Group</h5> : <h5 className="text-secondary mb-3">Create Employee Group</h5>}
            </Row>           

            <Formik                                        
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        try {
                            if (selectedGroup) {
                                await employeeGroupApi.updateEmployeeGroup(values);
                            } else {
                                await employeeGroupApi.createEmployeeGroup(values);
                            }
                            setIsUpdated(true);
                            setIsEditingGroup(false);
                        } catch (e){
                            setPageState(prevState => {return {...prevState, pageError: "Error Submitting Group" }})
                            console.error("Error Submitting Group" + e);
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={selectedGroup || groupDefaults}
                enableReinitialize={!!selectedGroup}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} md={12}>
                        <Row className="ms-5 pt-3">
                            <Col md={6}>

                                {/* Employee Group Name Input */}
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Employee Group Name: </Form.Label>
                                    <Form.Control
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name}
                                        type="text" 
                                        placeholder={"e.g. " + client.clientName + ' Managers'}
                                        isInvalid={touched.name && !!errors.name}                                        
                                    />
                                    {touched.name && (<Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>)}
                                </Form.Group>
                                <Row>
                                    {/* Username Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="userName">
                                        <Form.Label>Group Username: </Form.Label>
                                        <Form.Control
                                            name="userName"
                                            onChange={handleChange}
                                            value={values.userName}
                                            type="text"
                                            isInvalid={touched.userName && !!errors.userName}
                                        />
                                        {touched.userName && (<Form.Control.Feedback type="invalid">
                                            {errors.userName}
                                        </Form.Control.Feedback>)}
                                    </Form.Group> 

                                    {/* Password Key Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="passKey">
                                        <Form.Label>Group Passkey: </Form.Label>
                                        <Form.Control
                                            name="passKey"
                                            onChange={handleChange}
                                            value={values.passKey}
                                            type="text"                                            
                                            isInvalid={touched.passKey && !!errors.passKey}
                                        />
                                        {touched.passKey && (<Form.Control.Feedback type="invalid">
                                            {errors.passKey}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>
                                </Row>
                                <Row>
                                    {/* Send Manager Email */}
                                    <Form.Group className="mb-3 mt-3" controlId="sendManagerEmail">
                                        <Form.Check
                                            name="sendManagerEmail"
                                            type="checkbox"
                                            label="Create an email to send manager on course completion"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setShowEmailInputs(e.target.checked);
                                              }}
                                            checked={values.sendManagerEmail}
                                            isInvalid={touched.sendManagerEmail && !!errors.sendManagerEmail}
                                        />
                                        {touched.sendManagerEmail && (<Form.Control.Feedback type="invalid">
                                            {errors.sendManagerEmail}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>
                                    
                                    {/* Email Only Inputs - Conditionally rendered if sendManagerEmail is checked */}
                                    {showEmailInputs && (
                                        <>
                                            {/* Email Only Sent When Passed */}
                                            <Form.Group className="mb-3 ms-3" controlId="emailOnlySentWhenPassed">
                                                <Form.Check
                                                    name="emailOnlySentWhenPassed"
                                                    type="checkbox"
                                                    label="Only send email on pass"
                                                    onChange={handleChange}
                                                    checked={values.emailOnlySentWhenPassed}
                                                    isInvalid={touched.emailOnlySentWhenPassed && !!errors.emailOnlySentWhenPassed}
                                                />
                                                {touched.emailOnlySentWhenPassed && (<Form.Control.Feedback type="invalid">
                                                    {errors.emailOnlySentWhenPassed}
                                                </Form.Control.Feedback>)}
                                            </Form.Group>

                                            {/* Selected Manager Email Input */}
                                            <Form.Group className="mb-3 w-50" controlId="selectedManagerEmail">
                                                <Form.Label>Manager Email: </Form.Label>
                                                <Form.Control
                                                    name="selectedManagerEmail"
                                                    onChange={handleChange}
                                                    value={values.selectedManagerEmail}
                                                    type="text"
                                                    isInvalid={touched.selectedManagerEmail && !!errors.selectedManagerEmail}
                                                />
                                                {touched.selectedManagerEmail && (<Form.Control.Feedback type="invalid">
                                                    {errors.selectedManagerEmail}
                                                </Form.Control.Feedback>)}
                                            </Form.Group>
                                        </>
                                    )}
                                </Row>

                            </Col>
                            <Col md={{span: 4, offset: 1}}>
                                <Row className="bg-light shadow p-4 rounded-3 mb-5 mt-0">
                                    <Col>
                                        {/* Ask Post Login Questions */}
                                        <Form.Group className="mb-3" controlId="askPostLoginQuestions">
                                            <Form.Check
                                                name="askPostLoginQuestions"
                                                type="checkbox"
                                                label="Ask post-login questions"
                                                onChange={handleChange}
                                                checked={values.askPostLoginQuestions}
                                                isInvalid={touched.askPostLoginQuestions && !!errors.askPostLoginQuestions}
                                            />
                                            {touched.askPostLoginQuestions && (<Form.Control.Feedback type="invalid">
                                                {errors.askPostLoginQuestions}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>

                                        {/* Must Complete Course */}
                                        <Form.Group className="mb-3" controlId="mustCompleteCourse">
                                            <Form.Check
                                                name="mustCompleteCourse"
                                                type="checkbox"
                                                label="Must complete course before doing assessment"
                                                onChange={handleChange}
                                                checked={values.mustCompleteCourse}
                                                isInvalid={touched.mustCompleteCourse && !!errors.mustCompleteCourse}
                                            />
                                            {touched.mustCompleteCourse && (<Form.Control.Feedback type="invalid">
                                                {errors.mustCompleteCourse}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>

                                        {/* Generate Certificate */}
                                        <Form.Group className="mb-3" controlId="generateCertificate">
                                            <Form.Check
                                                name="generateCertificate"
                                                type="checkbox"
                                                label="Generate Certificate"
                                                onChange={handleChange}
                                                checked={values.generateCertificate}
                                                isInvalid={touched.generateCertificate && !!errors.generateCertificate}
                                            />
                                            {touched.generateCertificate && (<Form.Control.Feedback type="invalid">
                                                {errors.generateCertificate}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>

                                        {/* Show Take Another Course */}
                                        <Form.Group className="mb-3" controlId="showTakeAnotherCourse">
                                            <Form.Check
                                                name="showTakeAnotherCourse"
                                                type="checkbox"
                                                label="Show 'Take Another Course' link after course completion"
                                                onChange={handleChange}
                                                checked={values.showTakeAnotherCourse}
                                                isInvalid={touched.showTakeAnotherCourse && !!errors.showTakeAnotherCourse}
                                            />
                                            {touched.showTakeAnotherCourse && (<Form.Control.Feedback type="invalid">
                                                {errors.showTakeAnotherCourse}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={11}>
                                {/* Email Only Inputs - Conditionally rendered if sendManagerEmail is checked */}
                                {showEmailInputs && (
                                    <>
                                        {/* Email Message Input */}
                                        <Form.Group className="mb-3" controlId="emailMessage">
                                            <Form.Label>Email Message: </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="emailMessage"
                                                onChange={handleChange}
                                                value={values.emailMessage}
                                                rows="3"
                                                type="text" 
                                                isInvalid={touched.emailMessage && !!errors.emailMessage}
                                                />
                                                {touched.emailMessage && (<Form.Control.Feedback type="invalid">
                                                {errors.emailMessage}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>
                                        
                                        {/* Pass Message Input */}
                                        <Form.Group className="mb-3" controlId="passMessage">
                                            <Form.Label>Pass Message (Message to show on the final screen when a user passes a course. Leave blank to show default message)</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="passMessage"
                                                onChange={handleChange}
                                                value={values.passMessage}
                                                rows="3"
                                                type="text" 
                                                isInvalid={touched.passMessage && !!errors.passMessage}
                                            />
                                            {touched.passMessage && (<Form.Control.Feedback type="invalid">
                                                {errors.passMessage}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>
                                        
                                        {/* Fail Message Input */}
                                        <Form.Group className="mb-3" controlId="failMessage">
                                            <Form.Label>Fail Message (Message to show on the final screen when a user fails a course. Leave blank to show default message)</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="failMessage"
                                                onChange={handleChange}
                                                value={values.failMessage}
                                                rows="3"
                                                type="text" 
                                                isInvalid={touched.failMessage && !!errors.failMessage}
                                            />
                                            {touched.failMessage && (<Form.Control.Feedback type="invalid">
                                                {errors.failMessage}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>
                                    </>
                                )}
                                
                                <Row>
                                    {/* Create/Update Employee Group Button and Cancel Button */}
                                    <Col md={{span: 3, offset: 6}}>
                                        <Button className="btn-info mb-5 w-100" type="submit" disabled={isSubmitting || !dirty}>Save Employee Group</Button>
                                    </Col>
                                    <Col md={3}>
                                        <Button className="btn-info mb-5 w-100" type="button" onClick={() => setIsEditingGroup(false)}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {pageState.pageError && (
                            <Alert key="pageError" variant="danger mt-3">
                                {pageState.pageError}
                            </Alert>
                        )}
                    </Form> 
                )}
            </Formik>
            {selectedGroup && 
                <Row className="ms-3 mb-3">
                    <EmployeeGroupCourses groupId={groupId} />
                </Row>       
            }
        </>
    )
}